export default {
  notAllowTitle: "Impossible",
  notAllowText: "Vous n'avez pas les droits d'effectuer cette action",
  allNotAllowText: "Cet élément est fourni en standard par AAB Vie. Il n'est ni supprimable, ni modifiable.",

  client: {
    ticket: {
      create: true,
      update: true,
      show: true,
      all: true,
    },
    project_contrat: {
      create: true,
      update: true,
      show: true,
      all: true,
    },
  }
}
