export default {
  pages: {
    key: "title",
    data: [
      {
        url: "/",
        title: "Accueil",
        is_bookmarked: true,
        icon: "HomeIcon",
      },
      {
        url: "/contrat",
        title: "Contrats",
        is_bookmarked: true,
        icon: "BookOpenIcon",
      },
      {
        url: "/offer",
        title: "Offres",
        is_bookmarked: true,
        icon: "SunIcon",
      },
      {
        url: "/ticket",
        title: "Faire une demande",
        is_bookmarked: true,
        icon: "BookmarkIcon",
      },
    ]
  }
}
